/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
import {
  useState, useEffect, useRef,
} from 'react';
import { BiCurrentLocation } from 'react-icons/bi';
import notification from './notification';

export default function Autocomplete({
  onChange = () => { }, className = '', defaultValue = '', disabled = false, placeholder = 'Address', containerClass = '',
}) {
  const [address, setAddress] = useState({
    address: defaultValue ?? null,
    longitude: -80.191788,
    latitude: 25.761681,
  });
  const autoCompleteRef = useRef();

  const extractAddress = (place) => {
    const address = {
      city: '',
      province: '',
      postalCode: '',
      country: '',
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const { types } = component;
      const value = component.long_name;

      if (types.includes('locality')) {
        address.city = value;
      }

      if (types.includes('administrative_area_level_1')) {
        address.province = value;
      }

      if (types.includes('postal_code')) {
        address.postalCode = value;
      }

      if (types.includes('country')) {
        address.country = component.short_name;
      }
    });

    return address;
  };

  useEffect(() => {
    if (onChange) {
      onChange({
        address: address?.address,
        city: address?.city,
        province: address?.province,
        postalCode: address?.postalCode,
        country: address?.country,
        longitude: address?.longitude || '',
        latitude: address?.latitude || '',
        enteredAddress: autoCompleteRef.current.value,

      });
    }
  }, [address]);

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    setAddress({
      ...extractAddress({ ...place }), latitude, longitude, address: autoCompleteRef.current?.value,
    });
  };

  const onCurrentAddress = async ({ longitude, latitude }) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

    try {
      const res = await fetch(url).then((res) => res.json());

      const place = res.results[0];
      const latitude = place.geometry.location.lat;
      const longitude = place.geometry.location.lng;
      setAddress({
        ...extractAddress({ ...place }),
        latitude,
        longitude,
        address: place?.formatted_address,
      });
    } catch (e) {
      notification({
        type: 'error',
        title: 'Unable to get current location',
        description: 'Please try again later',
      });
    }
  };

  const getCurrentLocation = () => {
    const successCallback = (el) => {
      const { longitude, latitude } = el.coords;

      onCurrentAddress({ longitude, latitude });
    };

    const currentPos = navigator.geolocation.getCurrentPosition(successCallback);
  };

  // getting address from autocomplete
  useEffect(() => {
    if (autoCompleteRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
      autocomplete.setFields(['address_component', 'geometry']);
      autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    }
  }, [autoCompleteRef]);

  return (
    <div className={`relative form-input ${containerClass}`}>
      <input
        defaultValue={address?.address}
        name="location.address"
        ref={autoCompleteRef}
        className={`form-input focus:ring-secondary-400 focus:border-secondary-400 ${className} pr-8`}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => setAddress(e.target.value)}
      />
      {/* <button title="Use Current Location" type="button"
       onClick={getCurrentLocation} className="text-secondary-500
       absolute right-2 text-lg inset-y-0"><BiCurrentLocation /></button> */}
    </div>
  );
}
