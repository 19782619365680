import { isRejectedWithValue } from '@reduxjs/toolkit';

const mriPatientApiErrorHandler = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401 && localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
  return next(action);
};

export default mriPatientApiErrorHandler;
