import { useEffect, useState } from 'react';
import PhoneCodeSelect from './PhoneCodeSelect';

function PhoneInput({
  onChange, value = { phoneCode: '+1', phoneNumber: '', iso: 'US' }, placeholder = '(000) 000-0000', className = '', disabled = false,
}) {
  const [phoneCode, setPhoneCode] = useState(value?.phoneCode || '+1');
  const [countryCode, setCountryCode] = useState(value?.iso || 'US');
  const [phoneNumber, setPhoneNumber] = useState(value?.phoneNumber || '');

  const maskValue = (str) => {
    const x = str.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
  };

  useEffect(() => {
    if (onChange) {
      onChange({
        countryCode,
        phoneCode,
        phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
      });
    }
  }, [phoneCode, phoneNumber]);

  return (
    <div className="relative">
      <PhoneCodeSelect
        defaultValue={value.phoneCode}
        disabled={disabled}
        onChange={(e) => {
          setPhoneCode(e.phoneCode);
          setCountryCode(e.countryCode);
        }}
      />
      <input
        name="phone"
        disabled={disabled}
        onChange={(e) => (e.target.value.length <= 14 ? setPhoneNumber(e.target.value) : null)}
        value={maskValue(`${phoneNumber}`)}
        className={`form-input pl-14 ${className}`}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
}

export default PhoneInput;
