/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { Link } from 'react-router-dom';
import neck from '../assets/images/neck.svg';
import back from '../assets/images/back.svg';
import lowerback from '../assets/images/lowerback.svg';
import shoulder from '../assets/images/shoulder.svg';
import elbow from '../assets/images/elbow.svg';
import wrist from '../assets/images/wrist.svg';
import knee from '../assets/images/knee.svg';
import location from '../assets/images/searchlocation.svg';
import requestDate from '../assets/images/selectdate.svg';
import login from '../assets/images/paylogin.svg';
import receiver from '../assets/images/receiverreading.svg';
import gotoappointment from '../assets/images/gotoappointment.svg';
import confirmation from '../assets/images/confirmation.svg';

const bodyPart = [
  {
    name: 'Neck',
    logo: neck,
  },
  {
    name: 'Back',
    logo: back,
  },
  {
    name: 'Lower Back',
    logo: lowerback,
  },
  {
    name: 'Shoulder',
    logo: shoulder,
  },
  {
    name: 'Elbow',
    logo: elbow,
  },
  {
    name: 'Wrist/Hand',
    logo: wrist,
  },
  {
    name: 'Knee',
    logo: knee,
  },
];

const locations = [
  { name: 'Miami', available: true },
  { name: 'Austin', available: false },
  { name: 'Los Angeles', available: false },
  { name: 'Minneapolis', available: false },
];

const steps = [
  { name: 'Choose location', logo: location },
  { name: 'Select date', logo: requestDate },
  { name: 'Login & Pay', logo: login },
  { name: 'Receive  Report', logo: receiver },
  { name: 'Go to your appointment', logo: gotoappointment },
  { name: 'Receive confirmation call', logo: confirmation },
];
const stepsV = [
  { name: 'Choose location', logo: location },
  { name: 'Select date', logo: requestDate },
  { name: 'Login & Pay', logo: login },
  { name: 'Receive confirmation call', logo: confirmation },
  { name: 'Go to your appointment', logo: gotoappointment },
  { name: 'Receive Report', logo: receiver },
];

const benefits = [
  {
    title: 'Convenient & Fast',
    points: [
      'Appointments usually within 48hrs.',
      'No waiting for insurance authorizations.',
      'More convenient than the ER.',
    ],
  },
  {
    title: 'Affordable',
    points: [
      'Usually less than your insurance deductible/co-pay.',
      'A fraction of the cost of a traditional MRI.',
    ],
  },
  {
    title: 'Clear and Transparent Pricing',
    points: [
      'Our Fee is all inclusive per study/body part which means no surprises or upcharges.',
      'Includes reading from Board certified Radiologist.',
    ],
  },
  // {
  //   title: 'Comprehensive MRI Services',
  //   points: [
  //     'Covering basic to complex scans, meeting all MRI demands.',
  //     'An integrated facility offering a wide diagnostic spectrum.',
  //     'Tailored imaging solutions for precise patient needs.',
  //   ],
  // },
  // {
  //   title: 'Cutting-Edge Technology',
  //   points: [
  //     'Modern MRI machines ensure rapid and clear results.',
  //     'Advanced safety features prioritize patient comfort.',
  //     'Continuous tech upgrades to stay at the forefront of diagnostics.',
  //   ],
  // },
  // {
  //   title: 'Expert Team',
  //   points: [
  //     'In collaboration with Akumin Imaging, our board-certified radiologists lead in diagnostics.',
  //     'Regular training ensures up-to-date techniques.',
  //   ],
  // },
];

const faqs = [
  {
    id: 'abs',
    question: 'What is 379MRI?',
    answer: "379MRI.com is a technology marketplace for healthcare imaging services. It was created to help people find high quality, affordable, easy to schedule, self-pay MRI services. Navigating MRI's through healthcare insurance is often timely consuming, and expensive.",
    note: 'By choosing the self-pay option you forego submitting to your insurance. Submitting your service to insurance may result in additional charges from the insurance or provider. We suggest you evaluate your insurance plan deductibles and out of pocket expenses carefully.',
  },
  {
    id: 'abs123',
    question: 'What does the 379 price include?',
    answer: <div>
      Our rates are all inclusive without hidden costs or agendas. It includes:
      Imaging center fees, Board certified Radiologist fees,
      Radiologist report of the imaging study,
      <Link to="/refund-policy" className="font-semibold text-primary-500">Our Money Back Guarantee / Refund Policy HERE</Link>
    </div>,
  },
  {
    id: 'ab',
    question: 'Who should use 379MRI ?',
    answer: '379MRI.com is for anyone who requires a timely, affordable, non-contrast MRI study of a single body part. Please check with your doctor or provider to ask about which study is best for your indication/problem.',
    note: "379MRI.com is only a facilitator between patients and MRI facilities. Only your doctor is qualified to determine the need for an MRI, hence a doctor order is required.  Also, all findings are provided by the facility's radiologists.  379MRI.com is neither invovled nor responsible for any findings, omissions and/or follow-up actions  related to the exams.",
  },
  {
    id: 'bs',
    question: 'Do I need a Doctor/Provider’s order for my MRI?',
    answer: 'Yes, we want to make sure you are receiving the test that you require and are paying for. The order should clearly include: Patient Name, Imaging study ordered with CPT code, Diagnosis/ reason for the study, ordering Doctor/ Provider',
  },
  {
    id: 'aabs',
    question: 'Do you accept Medicare, Medicaid, or other assistance programs?',
    answer: 'No. 379MRI.com is a self-pay service and is not affiliated nor linked to any insurance or assistance programs.  Payments made should not be submitted to insurance and likely will not count towards any yearly out of pocket amounts.',
  },
  {
    id: 'aabsasd',
    question: 'Can I use my insurance?',
    answer: 'No. This is a “cash/ self pay” service. Our prices are likely lower than your insurance plan benefits. 379MRI will not provide insurance claim documentation (and this is part of the reason our prices are so low!)',
  },
  {
    id: 'aabsgg',
    question: 'Can I use my Health Savings Account?',
    answer: 'No, this program is a “cash/ self pay” service. We do not accept HSA or any type of health insurance programs.',
  },
  {
    id: 'aabsgg1231',
    question: 'What is the Satisfaction Guarantee?',
    answer: "If your doctor is not satisfied with the quality of the MRI you will have the option to re-do it at not cost or a full reason (we will need a doctor's note)",
  },
  {
    id: 'aabsrasdasd',
    question: 'Is 379MRI.com a provider?',
    answer: 'No, 379MRI.com is an online marketplace that connects consumers with  participating imaging centers. 379MRI allows consumers to choose a preferred imaging center and schedule a convenient, low cost, confirmed appointment.',
  },
  {
    id: 'aabsr',
    question: 'When do I pay my $379?',
    answer: 'At the time of booking',
  },
  {
    id: 'aabsf',
    question: 'Can I cancel or reschedule?',
    answer: 'Yes, you may cancel and/or reschedule your appointment within 48 hours. Cancellations/ reschedule requests within 48 hours may incur a $100 fee by your preferred imaging center.',
  },
  {
    id: 'candsdf',
    question: 'Can Submit my MRI to Insurance?',
    answer: '379MRI is a voluntary cash pay service.  If you subsequently submit the service to insurance, you may incur in additional charges from the insurance or provider.',
  },
  {
    id: 'gdrreunte',
    question: 'Are the appointment times guaranteed?',
    answer: 'When booking your appointment you will select your PREFERRED date and time for your study.  The facility will call you to confirm usually within 24 hours.',
  },
  {
    id: 'aabsa',
    question: 'Do you have Terms of Service?',
    answer: <div>
      Of course !
      {' '}
      {/* <Link to="/terms-and-conditions" className="font-semibold text-primary-500">HERE</Link> */}
      <Link to="/terms-and-conditions" className="font-semibold text-primary-500">Terms and Conditions</Link>
    </div>,
  },
];

const dummyAppointments = [
  {
    status: 'Completed',
    appointmentId: 'A45645',
    provider: 'Chloe Clark',
    dataAndTime: new Date('2023-05-29'),
    mobile: '+1 323-234-2324',
    referral: 'R993',
    _id: '2342342',
  },
  {
    status: 'Pending',
    appointmentId: 'A42695',
    provider: 'Chloe Clark',
    dataAndTime: new Date('2023-06-07'),
    mobile: '+1 323-234-2344',
    referral: 'R990',
    _id: '2342343',
  },
  {
    status: 'Scheduled',
    appointmentId: 'A48245',
    provider: 'Chloe Clark',
    dataAndTime: new Date('2023-06-04'),
    mobile: '+1 323-234-2744',
    referral: 'R983',
    _id: '2342322',
  },
  {
    status: 'Rescheduled',
    appointmentId: 'A45635',
    provider: 'Chloe Clark',
    dataAndTime: new Date('2023-06-29'),
    mobile: '+1 323-234-2044',
    referral: 'R998',
    _id: '2342302',
  },
];

const appointmentLegends = {
  completed: 'text-secondary-500',
  pending: 'text-[#E4C93A] ',
  SCHEDULED: 'text-green-500 ',
  COMPLETED: 'text-primary-500 ',
  CANCELED: 'text-danger-500 ',
  RESCHEDULED: 'text-yellow-500 ',
  MOBILE_UNVERIFIED: 'text-danger-500',
};

const bodyParts = [
  {
    name: 'Brain',
    value: '8345',
  },
  {
    name: 'Arms',
    value: '834a5',
  },
  {
    name: 'Legs',
    value: '834aw5',
  },
  {
    name: 'Knees',
    value: '83451',
  },
  {
    name: 'Back',
    value: '834556',
  },

];
const studyTypes = [
  {
    name: 'CT / CTA',
    value: '8345',
  },
  {
    name: 'NCV / EMG',
    value: '834a5',
  },
  {
    name: 'Ultrasound',
    value: '834aw5',
  },
  {
    name: 'X-ray',
    value: '83451',
  },
  {
    name: 'Stress test',
    value: '834556',
  },

];

// jsx for terms and conditions

// eslint-disable-next-line no-unused-vars
const termsAndCondition = (
  <div>
    <h1 className="font-semibold mb-4">General Disclaimer</h1>
    <p>
      379 MRI [LLC? Corp?] (“379MRI”) provides a service for patients to schedule health care appointments with imaging centers and other health care providers (“Participating Providers”) using 379MRI online platform which may be accessed via the 379MRI website www.379MRI.com and mobile applications (the “Platform”). These Patient Terms of Use and End User License Agreement (“Agreement”) govern a 379MRI’s member’s (“you” or “your”) use of the Platform, including the 379MRI website (the “Site”) and the mobile application made available on your mobile device, including all related documentation and Updates (the “Application”).
      PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING THE PLATFORM OR OTHERWISE ACCESSING OR USING THE PLATFORM. THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE.
      379 MRI is not a health care provider and is not reimbursable by any health insurance provider. The Platform provides a self/ cash pay service for patients and Participating Providers. 379 MRI is a technology-based marketplace for consumer driven imaging services. 379 MRI is not an insurance plan or provider. Patients with Federal and State funded healthcare plans such as Medicare and Medicaid are not eligible to use the Platform. 379 MRI does not recommend imaging centers and providers and makes no representations or warranties about the health care providers and imaging centers that appear on the Platform.
      BY CLICKING THE “I AGREE” BUTTON, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT ACKNOWLEDGE AND AGREE TO THE TERMS AND CONDITIONS SET FORTH HEREIN, DO NOT CLICK THE “I AGREE” BUTTON OR USE THE PLATFORM OR APPLICATION ON WHICH THIS AGREEMENT IS POSTED.
      379MRI MAY MODIFY THE PLATFORM AND THIS AGREEMENT, INCLUDING ANY INSTRUCTIONS,
      POLICIES OR GUIDELINES REFERENCED HEREIN, AT ANY TIME, IN ITS SOLE DISCRETION. YOUR
      ACCESS OR USE OF THE PLATFORM AFTER 379MRI POSTS ANY CHANGES TO THIS AGREEMENT AT WWW.379MRI.COM
      CONSTITUTES YOUR ACCEPTANCE OF THOSE CHANGES. YOU AGREE TO REVIEW PERIODICALLY TO ENSURE THAT
      YOU ARE FAMILIAR WITH THE MOST RECENT VERSION OF THIS AGREEMENT. 379MRI MAY, IN ITS SOLE DISCRETION,
      AND AT ANY TIME, DISCONTINUE THE PLATFORM OR ANY PART THEREOF, WITH OR WITHOUT NOTICE, OR MAY PREVENT OR
      LIMIT YOUR USE OF THE PLATFORM WITH OR WITHOUT NOTICE TO YOU. YOU AGREE THAT YOU DO NOT HAVE ANY RIGHTS IN
      THE PLATFORM AND THAT 379MRI WILL HAVE NO LIABILITY TO YOU IF THE PLATFORM IS DISCONTINUED IN WHOLE OR IN
      PART OR YOUR ABILITY TO ACCESS THE PLATFORM IS SUSPENDED OR TERMINATED.
    </p>
    {/* --disclosure-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">DISCLOSURES</h1>
      <p>You acknowledge that:</p>
      <ul className="list-disc flex flex-col gap-3">
        <li>379MRI does not provide any medical care or health care services.</li>

        <li>
          You are ultimately responsible for your health care. Neither the Platform nor 379MRI provides medical advice
          , nor recommend or endorse any of the health care providers/ imaging centers on this site.
          {' '}

        </li>

        <li>
          While 379MRI matches health care providers who have indicated an ability to assist you at an
          affordable rate with your health care needs, the selection of a health care provider and the
          evaluation of the provider’s ability to fully and competently meet your needs is your decision for which you
          assume all responsibility.

        </li>

        <li>
          Reliance on any information obtained from the use of this site or from any of the health care providers
          and imaging centers accessed through this site is solely at your own risk.

        </li>

        <li>379MRI is not a health services plan or a provider of health insurance benefits.</li>

        <li>
          Participating Providers are not employees or agents of 379MRI; they are independent contractors
          with whom you may create or maintain a patient relationship.

        </li>

        <li>
          379MRI does not credential Participating Providers and does not independently verify the credentialing
          information or qualifications supplied by Participating Providers.

        </li>

        <li>
          You may be subject to cancellation fees imposed by Participating Providers if scheduled
          appointments are not cancelled more than 24 hours in advance.

        </li>

        <li>
          You are solely responsible for payment of any costs or expenses incurred as a result of your use of
          the Platform, including any wireless, network, data usage, and roaming charges, and any appointment cancellation fees.

        </li>
      </ul>
    </div>

    {/* ----collection---- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4"> COLLECTION AND USE OF YOUR INFORMATION </h1>

      <p>
        You acknowledge that when you access and use the Platform or download, install, or use the Application, 379MRI may use automatic means to collect information about your use of the Platform and the Application, and about your mobile device. You will be required to provide certain information about yourself in order to use the Platform and to download, install, or use the Application or certain of its features or functionality. By using the

        Platform or downloading, installing, using, and providing information to or through the Platform,
        you consent to all actions taken by 379MRI with respect to your information in compliance with applicable laws.

      </p>
    </div>

    {/* ---restrictions---- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">RESTRICTIONS ON USE OF THE PLATFORM</h1>
      <p>You may use the Platform only for lawful purposes and in accordance with this Agreement. You agree not to use the Platform:</p>
      <ul className="list-disc flex flex-col gap-3">
        <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>

        <li>
          For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
        </li>

        <li>
          To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
        </li>

        <li>
          To impersonate or attempt to impersonate 379MRI, a 379MRI employee, another user, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing).

        </li>

        <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Platform, or which, as determined by us, may harm 379MRI or users of the Platform, or expose them to liability.</li>

      </ul>
      <p className="mt-6 mb-2">Additionally, you agree not to:</p>
      <ul className="list-disc flex flex-col gap-3">
        <li>Use the Platform in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Platform, including their ability to engage in real time activities through the Platform.</li>
        <li>
          Use any robot, spider, or other automatic device, process, or means to access the Platform for any purpose, including monitoring or copying any of the material on the Platform.
        </li>
        <li>
          Use any device, software, or routine that interferes with the proper working of the Platform.
        </li>

        <li>
          Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
        </li>

        <li>
          Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Platform, the server on which the Platform is stored, or any server, computer, or database connected to the Platform.
        </li>
        <li>
          Attack the Platform via a denial-of-service attack or a distributed denial-of-service attack.
        </li>

      </ul>
      <p className="mt-4">Otherwise attempt to interfere with the proper working of the Platform.</p>
    </div>
    {/* ---Property rights---- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">Intellectual Property Rights</h1>
      <p>
        The Platform and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by 379MRI, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret,
        and other intellectual property or proprietary rights laws.

      </p>
      <p className="my-2">
        This Agreement permit you to use the Platform for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish,
        download, store, or transmit any of the material on our Website, except as follows:
      </p>
      <ul className="list-disc flex flex-col gap-3">
        <li>· Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>

        <li>
          You may store files that are automatically cached by your Web browser for display enhancement purposes.
          {' '}

        </li>

        <li>
          You may print one copy of a reasonable number of pages of the Platform for your own personal, non-commercial use and not for further reproduction, publication, or distribution.
        </li>

      </ul>
      <p className="mt-6 mb-2">You must not:</p>
      <ul className="list-disc flex flex-col gap-3">
        <li>
          Modify copies of any materials from this site.

        </li>
        <li>
          Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.
          {' '}

        </li>
        <li>
          Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
          {' '}

        </li>

        <li>
          · You must not access or use for any commercial purposes any part of the Platform or any services or materials available through the Platform.
        </li>

      </ul>
      <p className="mt-4">
        If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Platform in breach of this Agreement, your right to use the Platform will stop immediately and you must, at our option, return or destroy any copies

        of the materials you have made. No right, title, or interest in or to the Platform or any content on the Platform is transferred to you, and all rights not expressly granted are reserved by 379MRI. Any use of the Platform not expressly permitted by this Agreement is a breach of this Agreement and may violate copyright, trademark, and other laws.

      </p>
    </div>

    {/* --trademarks-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">Trademarks</h1>
      <p>
        379MRI name, the terms [COMPANY TRADEMARKS], [379MRI logo,] and all related names, logos, product and service names, designs, and slogans are trademarks of 379MRI or its affiliates or licensors. You must not use such marks without the prior written permission of 379MRI. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.

      </p>

    </div>
    {/* --DISCLAIMER OF WARRANTIES-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">DISCLAIMER OF WARRANTIES</h1>
      <p>
        THE PLATFORM, INCLUDING THE APPLICATION, IS PROVIDED TO YOU "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, 379MRI, ON ITS OWN BEHALF AND ON BEHALF OF ITS LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE PLATFORM, INCLUDING THE APPLICATION AND ALL CONTENT THEREIN, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, 379MRI PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.

      </p>

      <p className="my-2">
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.

      </p>

    </div>
    {/* --LIMITATION OF LIABILITY-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">LIMITATION OF LIABILITY</h1>
      <p>
        IN NO EVENT WILL 379MRI, OR ITS LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE PLATFORM, INCLUDING THE APPLICATION, OR WITH THE DELAY OR INABILITY TO USE THE PLATFORM, WHETHER UNDER CONTRACT, LAW, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, EVEN IF 379MRI HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. ADDITIONALLY, IN NO EVENT WILL 379MRI’s

        COLLECTIVE AND TOTAL LIABILITY TO YOU UNDER THE TERMS AND CONDITIONS OR OTHERWISE IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW) EXCEED FIFTY DOLLARS (US$50.00). 379MRI MAKE NO WARRANTIES, EXPRESS OR IMPLIED, THAT THE USE OF THE SERVICES DOES NOT INFRINGE
        ANY INTELLECTUAL PROPERTY RIGHT OF ANY THIRD PARTY.
      </p>

    </div>
    {/* --claims-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">CLAIMS</h1>
      <p>
        Any claim or cause of action arising out of, in connection with, or related to your access or use of the Platform or this Agreement must be filed within one (1) year after the accrual of the cause of action arose or it will be forever barred. All statutes or provisions of law that would toll or otherwise affect the running of the period of limitation are hereby waived, and no such statute or provision of law will operate to extend the period limited in this paragraph. You agree that any dispute, claim or cause of action arising out of or connected with the Platform or this Agreement, will be resolved individually, without resort to any form of class action, representative action, or collective action.

      </p>

    </div>
    {/* --INDEMNIFICATION-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">INDEMNIFICATION</h1>
      <p>
        BY ACCESSING OR USING THE PLATFORM, INCLUDING THE APPLICATION, YOU AGREE TO INDEMNIFY,
        AND HOLD HARMLESS 379MRI, AND ITS CURRENT, FUTURE OR FORMER OFFICERS, DIRECTORS, EQUITY HOLDERS, EMPLOYEES,
        AGENTS, LICENSORS, CONTRACTORS, SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE “379MRI INDEMNITEES”) ON DEMAND, FROM AND
        AGAINST ANY ACTUAL OR ALLEGED CLAIMS, DEMANDS, CAUSES OF ACTION, JUDGMENTS, DAMAGES, LOSSES, LIABILITIES, AND ALL
        COSTS AND EXPENSES OF DEFENSE (INCLUDING REASONABLE ATTORNEYS’ AND EXPERTS’ FEES) INCURRED BY ANY OF THE 379MRI AND
        / OR 379MRI INDEMNITEES ARISING OUT OF OR RELATING TO: (A) YOUR BREACH OF THIS AGREEMENT INCLUDING ANY ADDITIONAL
        INSTRUCTIONS, GUIDELINES OR POLICIES ISSUED BY 379MRI; (B) YOUR VIOLATION OF ANY APPLICABLE LAW, RULE OR REGULATION;
        (C) ANY CLAIM BY A THIRD PARTY THAT IS BASED ON YOUR USE OF THE PLATFORM OR THE CONTENT THEREIN; (D) INFORMATION OR
        MATERIAL POSTED OR TRANSMITTED THROUGH YOUR COMPUTER OR ACCOUNT, EVEN IF NOT SUBMITTED BY YOU; (E) ANY MISREPRESENTATION
        MADE BY YOU, OR THE INTENTIONAL MISCONDUCT, CRIMINAL ACTS OR NEGLIGENCE OF, YOU; AND (F) ANY DISPUTE BETWEEN YOU AND
        ANOTHER USER OF THE PLATFORM. YOU WILL COOPERATE AS FULLY AND AS REASONABLY REQUIRED IN 379MRI’s DEFENSE OF ANY CLAIM.

      </p>

    </div>
    {/* --ARBITRATION AGREEMEN-- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">ARBITRATION AGREEMEN</h1>
      <p className="my-2">
        <strong>Mandatory Arbitration.</strong>
        {' '}
        YOU AND 379MRI AGREE TO SUBMIT ANY AND ALL DISPUTES ARISING OUT OF OR RELATING IN ANY WAY TO YOUR USE OF THE PLATFORM (EACH, A “DISPUTE”), TO BINDING ARBITRATION PURSUANT TO THE FEDERAL ARBITRATION ACT, WHICH SHALL GOVERN THE INTERPRETATION

        AND ENFORCEMENT OF THIS ARBITRATION AGREEMENT (“ARBITRATION AGREEMENT”). YOU WAIVE THE RIGHT TO A TRIAL BY JURY AND ANY RIGHT TO HAVE THE DISPUTE HEARD IN COURT.

      </p>

      <p className="my-2">
        <strong>AAA Consumer Arbitration Rules.</strong>
        {' '}
        A single arbitrator with the American
        Association (“AAA”) will conduct the arbitration, and the award may not exceed the relief allowed by applicable law.
        Judgment on the arbitrator’s award may be entered in any court having jurisdiction thereof.
        The arbitration will be conducted at a mutually agreed location. The AAA’s Consumer Arbitration Rules will apply.
        The AAA’s rules and a form that can be used to initiate arbitration proceedings are available at
        {' '}
        <a href="http://www.adr.org." target="_blank" rel="noreferrer">http://www.adr.org.</a>
        {' '}
        All filing fees, administrative fees, and arbitrator fees and
        expenses will be paid in accordance with the applicable AAA rules.

      </p>

      <p className="my-2">
        <strong>Arbitration Class Action Waiver.</strong>
        {' '}
        You and 379MRI agree that the arbitration will be conducted solely on an individual basis and not on a class, representative, consolidated, or private attorney general basis. A Dispute may not be consolidated with a claim brought or discovery by any person or entity that is not a party to the arbitration proceeding. The arbitrator may not award relief to any person or entity other than a party to the arbitration proceeding and may only award such relief
        as is necessary to provide relief to a party to the arbitration proceeding.

      </p>

      <p className="my-2">
        <strong>Non-Arbitration Class Action and Jury Waiver.</strong>
        {' '}
        You and 379MRI agree that if for any reason a Dispute proceeds in court rather than arbitration: (1) you and 379MRI waive any right to a jury trial; (2) the Dispute will proceed solely on an individual, non-class, non-representative basis; and (3) neither you nor 379MRI may be a class representative or class member or otherwise participate in any class, representative, consolidated, or private attorney general processing.

      </p>

    </div>

    {/* -----misclanoiurs---- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">MISCELLANEOUS</h1>
      <div>
        <h4 className="font-semibold my-2">Relationship of Parties</h4>
        <p>
          Both you and 379MRI acknowledge and agree that no partnership is formed and neither you nor 379MRI has the power or the authority to obligate or bind the other.

        </p>
      </div>
      <div>
        <h4 className="font-semibold my-2">Assignment</h4>
        <p>
          379MRI may assign this Agreement, in whole or in part, at any time with or without notice to you. You may not assign this Agreement, or any rights, benefits or obligations hereunder, to any other person or entity. Any attempt by you to do so is void. You may not transfer to anyone else, either temporarily or permanently, any rights to use all or any part of the Platform. To the extent that you allow a third party to use your device, you will remain solely responsible for the access and use of the Platform by such third party.

        </p>
      </div>
      <div>
        <h4 className="font-semibold my-2">Entire Agreement/Severability/Waiver</h4>
        <p>
          This Agreement and any additional instructions, guidelines or policies issued by 379MRI, including those posted in the Platform, constitute the entire agreement between you and

          379MRI governing your use of the Platform and all matters addressed herein, and supersedes any prior agreements or other documents between you and 379MRI regarding your use of the Platform and the matters herein. You may also be subject to additional terms and conditions of third parties (including, but not limited to, terms and conditions from your wireless carrier or operator) that may apply to your use of the Platform. If any provision of this Agreement is held to be invalid by any law, rule, order or regulation of any government or by the final determination of any state or federal court, such invalidity will not affect the enforceability of any other provision of this Agreement. The failure of 379MRI to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision

        </p>
      </div>
      <div>
        <h4 className="font-semibold my-2">Geographic Restrictions</h4>
        <p>
          Although the Platform is designed for operation within the U.S., 379MRI recognizes that it may be possible for you to obtain access to the Platform from jurisdictions outside the U.S., and that 379MRI may not have the practical ability to prevent such access. TO THE MAXIMUM EXTENT PERMITTED BY LAW, 379MRI MAKES NO REPRESENTATION AS TO THE USE OF THE APPLICATION OUTSIDE THE U.S. OR COMPLIANCE OF THE PLATFORM OR THIS AGREEMENT WITH ANY APPLICABLE LAW. IF YOU CHOOSE TO ACCESS THE APPLICATION FROM OUTSIDE THE U.S., YOU DO SO AT YOUR OWN INITIATIVE AND YOU ARE SOLELY RESPONSIBLE FOR COMPLYING WITH APPLICABLE LOCAL LAWS.

        </p>
      </div>
      <div>
        <h4 className="font-semibold my-2">Governing Law and Venue</h4>
        <p>
          By using the Platform, you agree that the statutes and laws of the United States and the State of Florida without regard to conflicts of laws principles, will apply to all matters relating to use of the 379MRI Service, and you agree that any litigation will be subject to the exclusive jurisdiction of the state or federal courts Miami-Dade County, Florida. The United Nations Convention on Contracts for the International Sale of Goods (1980) is hereby excluded in its entirety from application to this Agreement.
        </p>
      </div>
      <div>
        <h4 className="font-semibold my-2">Remedies</h4>
        <p>
          At its option, 379MRI may seek all remedies available to it in this Agreement, under law and in equity, including injunctive relief in the form of specific performance to enforce this Agreement and any additional instructions, guidelines or policies issued by 379MRI.
        </p>
      </div>
      <div>
        <h4 className="font-semibold my-2">Survival</h4>
        <p>
          The following sections of this Agreement will survive any such expiration or termination:
          <strong> Disclosures, Disclaimer of Warranties, Ownership and Intellectual Property, Limitation of Liability, Claims, Indemnification, Arbitration Agreement, and Miscellaneous </strong>
          {' '}
          provisions.
          {' '}

        </p>
      </div>
    </div>

    {/* -----Additional Terms of Use---- */}
    <div className="my-4">
      <h1 className="font-semibold mb-4">Additional Terms of Use</h1>
      {/* --sms and text---- */}
      <div>
        <p className="my-2">SMS Policy(s)/ COMMUNICATION VIA EMAIL AND TEXT MESSAGE</p>
        <p>
          When using email or text message to communicate with 379MRI, it is important to consider the following risks:
        </p>
        <ol className="flex flex-col gap-2 list-decimal">
          <li> E-mail or text messages can be easily circulated, forwarded, and stored in various paper and electronic files.</li>

          <li> They can be broadcast worldwide and received by unintended recipients.</li>

          <li> There is a risk of misaddressing an email or text message.</li>

          <li> E-mails or text messages are more susceptible to falsification compared to handwritten or signed documents.</li>

          <li> Backup copies of e-mails or text messages may exist even after deletion by the sender or recipient.</li>
          <li>
            {' '}
            Employers and online service providers have the right to archive and inspect emails or text messages
            transmitted through their systems.

          </li>

          <li> E-mails or text messages can be intercepted, altered, forwarded, or used without authorization or detection.</li>

          <li>They can be used to introduce viruses into computers or mobile devices.</li>

          <li> E-mails or text messages can be used as evidence in court.</li>
        </ol>
      </div>
      {/* --Email and text---- */}
      <div>
        <p className="my-2 mt-6">CONDITIONS FOR THE USE OF E-MAIL OR Text Messages:</p>
        <p className="my-2">
          Due to the aforementioned risks, 379MRI cannot guarantee the security and confidentiality of email or text message communication. Therefore, patients must consent to the use of email or text message for exchanging patient information, while agreeing to the following conditions:
        </p>
        <ol className="flex flex-col gap-2 list-disc">

          <li>
            {' '}
            All emails or text messages regarding diagnosis or treatment
            will be printed and included in the patient’s medical record. Authorized individuals,
            such as staff and billing personnel, will have access to these emails or text messages
            as part of the medical record.

          </li>

          <li>
            379MRI may internally forward emails or text messages within the organization
            and externally to your Provider’s staff or agent for diagnosis, treatment, reimbursement,
            and other necessary purposes. However, emails or text messages will not be forwarded to
            independent third parties without the patient’s prior written consent, except as authorized or
            required by law or as per the Notice of Privacy Practices.

          </li>

          <li>
            379MRI will make reasonable efforts to read and respond promptly to emails
            or text messages, but cannot guarantee specific response times. Therefore, patients should not use these communication methods for medical emergencies or time-sensitive matters.

          </li>

          <li>
            If a patient does not receive a response within a reasonable time period, it is their
            responsibility to follow up and confirm if the intended recipient received the email or text message and when they will respond.

          </li>

          <li>
            Patients should refrain from using email or text message to discuss sensitive medical information,
            such as sexually transmitted diseases, AIDS/HIV, mental health, developmental disability, or substance abuse.

          </li>
          <li>
            {' '}
            Patients are responsible for informing 379MRI of any specific types of information they do
            not want to be sent via email or text message.

          </li>
          <li>
            {' '}
            Patients must safeguard their passwords or other means of access to email or text message. 379MRI is not liable
            for breaches of confidentiality caused by the patient or any third party.

          </li>

          <li>It is the patient’s responsibility to follow up and/or schedule an appointment if necessary</li>

        </ol>
      </div>
      {/* --Email and text---- */}
      <div>
        <p className="my-2 mt-6">INSTRUCTIONS:</p>
        <p className="my-2">
          To communicate via email or text message, patients should:
        </p>
        <ol className="flex flex-col gap-2 list-disc">
          <li>Limit or avoid using their employer’s computer for communication.</li>

          <li>Notify 379MRI of any changes in their email address or phone number.</li>

          <li>Include their name in the body of the email or text message.</li>

          <li>Use specific categories in the email’s subject line for routing purposes (e.g., billing question).</li>

          <li>Review the content of the email or text message to ensure clarity and provide all relevant information before sending it to 379MRI.</li>

          <li>Take precautions to maintain the confidentiality of email or text messages, such as using screen savers and protecting computer passwords.</li>

          <li>Withdraw consent for email or text message communication by sending an email, text message, or written communication to the Provider</li>
        </ol>
      </div>
      {/* --PATIENT ACKNOWLEDGEMENT AND AGREEMENT---- */}
      <div>
        <p className="my-2 mt-6">PATIENT ACKNOWLEDGEMENT AND AGREEMENT</p>
        <p className="my-2">
          By providing your email or text messages or personal information to 379MRI, you acknowledge the terms and information above. You understand the risks associated with the communication of email and SMS between 379MRI and yourself, and consent to the conditions herein. In addition, you agree to the instructions outlined herein, as well as any other instructions that 379MRI or our Provider may impose to communicate with patients by email or text messages.

        </p>

        <p className="my-2">By agreeing to the Terms and Conditions, you also agree to receive and/or obtain text messages from 379MRI, you specifically authorize 379MRI to send text messages to any phone number you have provided to them through any method, including but not limited to website forms, phone conversations, or email. You acknowledge that standard text messaging rates may apply for any messages received from 379MRI. You understand that you have the right to revoke this permission in writing at any time by contacting 379MRI. You agree not to hold 379MRI liable for any electronic messaging charges or fees incurred as a result of this service. Additionally, you acknowledge that if your contact or cell phone number changes, it is your responsibility to inform 379MRI</p>

      </div>

    </div>

  </div>
);

export {
  benefits, faqs, dummyAppointments, appointmentLegends, bodyParts, studyTypes, termsAndCondition, bodyPart, steps, locations, stepsV,
};
