/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsFillCheckSquareFill, BsChevronDown } from 'react-icons/bs';
import { bodyParts } from '../constants';

function MultiSelect({
  placeholder = 'Select...', values = [], options = [], onChange = () => { }, allowSearch = false, type = 'multi',
}) {
  const [optionResults, setOptionResults] = useState(options);
  const [selected, setSelected] = useState(values);
  const [searchText, setSearchText] = useState('');

  const handleSelect = (el) => {
    if (type === 'multi') {
      let arr = [...selected];
      if (arr.includes(el)) {
        arr = arr.filter((item) => item !== el);
      } else {
        arr.push(el);
      }

      setSelected(arr);
    } else {
      setSelected([el]);
    }
  };

  const handleSearch = (text = '') => {
    if (text.length) {
      const tempResults = options.filter((e) => e.name.toLowerCase().includes(text.toLowerCase()));
      setOptionResults(tempResults);
    } else {
      setOptionResults(options);
    }
  };

  const handleRemove = (el) => {
    let arr = [...selected];
    arr = arr.filter((item) => item !== el);

    setSelected(arr);
  };

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <div className="relative z-50">
      <Menu as="div" className=" relative z-50">
        <div>
          <Menu.Button className="w-full bg-white flex items-center rounded-full border-text-300 border focus:border-secondary-500 p-2 whitespace-nowrap truncate relative pl-[1rem]">
            {(selected[0]?.name) ? selected.map((el) => el.name).join(', ') : placeholder }

            <BsChevronDown className="absolute inset-y-3 right-2 bg-white" />
          </Menu.Button>
        </div>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          className="bg-white shadow-lg z-50 relative"
        >
          {/* ---selected items list--- */}

          <Menu.Items className="origin-top-right absolute right-0 w-full py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">

            {allowSearch && (
            <div className="p-2">
              <input type="search" placeholder="search" className="form-input text-sm" onChange={(e) => handleSearch(e.target.value)} />
            </div>
            )}

            { type !== 'single' && (
            <div className="bg-white p-2 flex flex-wrap gap-2">
              {
                selected.map((ele) => (
                  <span className="bg-secondary-500 text-white rounded-full px-3 py-2 text-sm flex items-center w-min" key={ele.name}>
                    <span className="whitespace-nowrap w-[2rem] truncate">
                      {ele.name}
                    </span>
                    {' '}
                    <MdOutlineCancel className="ml-4 cursor-pointer" onClick={() => handleRemove(ele)} />
                  </span>
                ))
              }

            </div>
            )}

            <div className=" p-3 flex flex-col h-auto max-h-[10rem] overflow-x-hidden overflow-y-scroll select-scroll">

              {
                optionResults.map((part) => (
                  <Menu.Item key={part?.value}>
                    {
                      ({ close }) => (
                        <button
                          type="button"
                          className={`btn bg-white text-text-500 text-left hover:bg-white text-sm ${selected.includes(part) && 'font-semibold'} `}
                          key={part.value}
                          onClick={() => { handleSelect(part); close(); }}
                        >
                          {selected.includes(part)
                            ? <BsFillCheckSquareFill className="mr-3 text-secondary-500 text-base" /> : <span className="h-4 w-4 border border-text-300 rounded mr-3" />}
                          {' '}
                          {part.name}
                          {' '}
                        </button>
                      )
                    }

                  </Menu.Item>
                ))
              }

            </div>

          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default MultiSelect;
