/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';

const getCalendarDates = (month, year) => {
  const calendarDates = [];
  const totalDaysInCurrentMonth = new Date(year, month + 1, 0).getDate();
  const offsetDays = new Date(year, month, 0).getDay();

  const insertDate = (y, m, d, insertFromStart = false) => {
    const temp = new Date(y, m, d);
    calendarDates[insertFromStart ? 'unshift' : 'push']({
      // dateString: temp.toLocaleDateString('en-ca'),
      dateString: moment(temp).format('YYYY-MM-DD'),
      date: temp.getDate(),
      month: temp.getMonth(),
    });
  };

  for (let i = 0; Math.abs(i) < offsetDays; i -= 1) insertDate(year, month, i, true);
  for (let i = 1; i <= totalDaysInCurrentMonth; i += 1) insertDate(year, month, i);
  for (let i = 1; calendarDates.length < 42; i += 1) insertDate(year, month + 1, i);
  return calendarDates;
};

function DateSlider({
  selectedDate, setSelectedDate = () => { },
  selectedMonth = {}, availableDates = [], setSlot = () => {},
}) {
  const today = new Date();

  const scrollRef = useRef();

  const [calendarDates, setCalendarDates] = useState([]);

  const handleScroll = (d) => {
    if (d === 'left') {
      scrollRef.current.scrollLeft -= 80;
    } else {
      scrollRef.current.scrollLeft += 80;
    }
  };

  const handleDateSelect = (el) => {
    setSelectedDate(el);
    setSlot(null);
  };

  useEffect(() => {
    setSelectedDate(selectedDate);
  }, []);

  useEffect(() => {
    setCalendarDates(getCalendarDates(selectedMonth?.month, selectedMonth?.year));
  }, [selectedMonth?.month, selectedMonth?.year]);

  return (
    <div className="flex items-center gap-4 w-full justify-between">
      <button onClick={() => handleScroll('left')} title="left"><BsChevronLeft /></button>
      <div ref={scrollRef} className="flex-2 flex gap-4 w-full items-center overflow-x-scroll overflow-y-hidden h-auto py-[1rem] date-slider">
        {
          calendarDates.filter((el) => el.month === selectedMonth?.month
          && moment(el.dateString).isSameOrAfter(moment().format('YYYY-MM-DD')))
            .map((el) => (
              <button
                key={el.dateString}
                onClick={() => handleDateSelect(el)}
                disabled={!availableDates.includes(el.dateString)}
                className={`text-sm w-[8rem] h-[5rem] border-2 rounded-[1rem] px-4 py-2 hover:scale-[1.2] ${!availableDates.includes(el.dateString) ? 'bg-light-600 border-light-600 cursor-not-allowed' : selectedDate?.dateString !== el?.dateString ? 'border border-success-500 text-white bg-success-500' : 'bg-secondary-500 text-white font-semibold'}   transition `}
              >
                <span
                  className="block whitespace-nowrap font-semibold"
                >
                  {moment(el?.dateString).format('MMM DD')}

                </span>
                <span
                  className="block"
                >
                  {moment(el?.dateString).format('ddd')}

                </span>
              </button>
            ))
        }
      </div>
      <button onClick={() => handleScroll('right')} title="right"><BsChevronRight /></button>

    </div>
  );
}

export default DateSlider;
