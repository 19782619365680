/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import cardBrands from './CardBrands';

const isUserLoggedIn = () => localStorage.getItem('token') && localStorage.getItem('user');

const getUserDetails = () => {
  const user = localStorage.getItem('user');

  return JSON.parse(user);
};

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const fixedDigitNumber = (num, digits = 4) => (num).toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false });

const formatUSPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

const serializeQueryParams = function (obj, prefix) {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push((v !== null && typeof v === 'object')
        ? serializeQueryParams(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }
  return str.join('&');
};

const timezonesList = [
  { name: 'Eastern Standard Time (EST)', value: '1' },
  { name: 'Central Standard Time (CST)', value: '2' },
  { name: 'Pacific Standard Time (PST)', value: '3' },
  { name: 'Mountain Standard Time (MST)', value: '4' },
  { name: 'Hawaii–Aleutian Standard Time (HST)', value: '5' },
  { name: 'Alaska Standard Time (AKST)', value: '6' },
];

const gendersList = [
  { name: 'Male', value: 'Male' },
  { name: 'Female', value: 'Female' },
  { name: 'Other', value: 'Other' },
];

const languageList = [
  { name: 'English', value: 'en' },
  { name: 'Spanish', value: 'es' },
  { name: 'Others', value: 'other' },
];
const legendCodes = [
  {
    title: 'Available',
    className: 'h-6 w-6 rounded-full bg-success-500 border-2 border-success-500',
  },
  {
    title: 'Unavailable',
    className: 'h-6 w-6 rounded-full bg-light-600',
  },
  {
    title: 'Selected',
    className: 'h-6 w-6 rounded-full bg-secondary-500',
  },
];

export {
  isUserLoggedIn,
  classNames,
  fixedDigitNumber,
  formatUSPhoneNumber,
  timezonesList,
  gendersList,
  languageList,
  cardBrands,
  getUserDetails,
  serializeQueryParams,
  legendCodes,
};
