/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { Modal, notification, Spinner } from '..';
import { getUserDetails } from '../../utils';
import { useResendVerifyOTPMutation } from '../../services/api';

function OtpModal({
  open = false, setOpen = () => { }, onSubmit = () => { },
  submiting = false, resending = false, onResend = () => {},
  title = '', method = 'phone',
}) {
  // api calls

  const [pcpName, setPcpName] = useState('');

  // submit handler
  // const handleSubmit = () => {
  //   const dataToSend = {
  //     pcpName,
  //   };

  //   onChange(dataToSend);

  //   setOpen(false);
  // };

  const handleSubmit = async () => {
    const fields = document.querySelectorAll('.otp-input-modal');
    const otp = [...fields].map((field) => field.value).join('');

    if (otp.length !== 4) {
      return notification({
        title: 'Incomplete OTP!',
        description: 'Please enter 4 digits otp',
        type: 'info',
      });
    }

    onSubmit(otp);
  };

  const handleKeyDown = (e) => {
    const fields = document.querySelectorAll('.otp-input-modal');

    e.preventDefault();
    const i = Number(e.currentTarget.dataset.index);
    if (e.key === 'Backspace') {
      fields[i].value = '';
      if (i !== 0) fields[i - 1].focus();
    } else if (e.key >= 0 && e.key <= 9) {
      fields[i].value = e.key;
      if (i !== fields.length - 1) fields[i + 1].focus();
    } else if (e.keyCode > 64 && e.keyCode < 91) {
      fields[i].value = String.fromCharCode(e.keyCode);
      if (i !== fields.length - 1) fields[i + 1].focus();
    } else if (i === 3 && e.keyCode === 13) handleSubmit();
  };

  return (
    <Modal open={open}>
      <div className="inline-block  w-auto md:w-1/3 lg:w-1/3
        md:h-auto bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle"
      >
        {/* ---header--- */}
        <div className="p-3 border-b">
          <h4 className="font-semibold">{title}</h4>
        </div>
        {/* --content container---- */}

        <div className="p-3">
          <h3>
            Please enter the 4-digit code sent to your
            {' '}
            { method === 'phone' ? 'mobile number' : 'email'}
          </h3>
          {/* --textbox---- */}
          <div className="relative rounded-md flex justify-center gap-4 text-black mt-4">
            <input
              type="text"
              data-index="0"
              className="form-input otp-input-modal focus:placeholder-transparent py-3 text-center font-medium px-5 text-lg w-[3.75rem]"
              placeholder="_"
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <input
              type="text"
              data-index="1"
              className="form-input otp-input-modal focus:placeholder-transparent py-3 text-center font-medium px-5 text-lg w-[3.75rem]"
              placeholder="_"
              onKeyDown={(e) => handleKeyDown(e)}

            />
            <input
              type="text"
              data-index="2"
              className="form-input otp-input-modal focus:placeholder-transparent py-3 text-center font-medium px-5 text-lg w-[3.75rem]"
              placeholder="_"
              onKeyDown={(e) => handleKeyDown(e)}

            />
            <input
              type="text"
              data-index="3"
              className="form-input otp-input-modal focus:placeholder-transparent py-3 text-center font-medium px-5 text-lg w-[3.75rem]"
              placeholder="_"
              onKeyDown={(e) => handleKeyDown(e)}

            />
          </div>

          {/* --button container---- */}
          <div className="mt-6 flex justify-between flex-wrap-reverse gap-2 ">
            <button type="button" disabled={resending} className="btn btn-primary-outline rounded-full" onClick={onResend}>
              {' '}
              {resending && <Spinner className="mr-2 text-primary-500" />}
              {' '}
              Resend OTP
            </button>
            <div className="flex items-center gap-4">
              <button type="button" className="btn rounded-full" onClick={() => setOpen(false)}>Cancel</button>
              <button type="button" disabled={submiting} className="btn btn-secondary rounded-full" onClick={handleSubmit}>
                {' '}
                { submiting && <Spinner className="mr-2" />}
                {' '}
                Submit
              </button>
            </div>
          </div>
        </div>

      </div>
    </Modal>
  );
}

export default OtpModal;
