import { configureStore } from '@reduxjs/toolkit';
import { mriPatientApi } from '../services/api';
import mriPatientApiErrorHandler from '../services/api/errorHandler';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: {
    [mriPatientApi.reducerPath]: mriPatientApi.reducer,
    ...rootReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(mriPatientApi.middleware)
    .concat(mriPatientApiErrorHandler),
});

export default store;
