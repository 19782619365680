import { useState } from 'react';

function Select({
  options, type = 'simple', placeholder, onChange, defaultValue, className = '',
}) {
  if (defaultValue) {
    if (typeof defaultValue !== 'object') defaultValue = [defaultValue];
  } else defaultValue = [];

  const optionsMap = new Map();
  options.forEach((el) => optionsMap.set(el.value, el.name));

  const initialValue = new Set();
  defaultValue.forEach((value) => {
    // check defaukt value exist in options map
    const isExist = options.filter((el) => el.value === value);
    if (isExist.length > 0) initialValue.add(value);
  });
  const [selected, setSelected] = useState(initialValue);

  const handleChange = (el) => {
    const newValue = el.target.value;
    const temp = new Set(selected);
    if (type === 'multiple') {
      if (temp.has(newValue)) temp.delete(newValue);
      else temp.add(newValue);
    } else {
      temp.clear();
      temp.add(newValue);
    }
    setSelected(temp);
    if (onChange) onChange(Array.from(temp));
  };

  return (
    <select
      className={`${selected.size === 0 ? 'text-text-300' : 'text-text-500'} form-input ${className}`}
      value="selected"
      placeholder={placeholder}
      onChange={handleChange}
    >
      <option className="hidden opacity-0" value="selected">
        {selected.size > 0
          ? Array.from(selected).map((value) => optionsMap.get(value)).join(type === 'simple' ? '' : ', ')
          : placeholder}
      </option>
      {
        // for 0 options
        options.length === 0 && <option value="selected" disabled>No Options Available</option>
      }
      {options.map((el) => (
        <option
          key={el.value}
          value={el.value}
          className={selected.has(el.value) ? 'bg-secondary-500 text-white hover:bg-secondary-500/70 hover:text-white' : 'hover:bg-secondary-500/70 hover:text-white'}
        >
          {el.name}
        </option>
      ))}
    </select>
  );
}

export default Select;
